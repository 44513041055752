import React, { useState, ChangeEvent, useEffect, ChangeEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiURL = process.env.REACT_APP_API_URL;

interface BankData {
    bank_id: string;
    bank_name: string;
    status: string;
}

interface AccountData {
    account_type_id: string;
    account_type_name: string;
}

interface ResponseProp {
    onreportResponse: (obj: { report_id: string, report_status: string, start_date: string, end_date: string, report_filename: string }) => void;
}

const Generate: React.FC<ResponseProp> = ({ onreportResponse }) => {
    document.title = 'Add statement';
    const [files, setFiles] = useState<File[]>([]);
    const [reportname, setreportName] = useState('');
    const [bank, setBank] = useState('1');
    const [account, setAccount] = useState('');
    const [fileError, setfileError] = useState('');
    const [banks, setBanks] = useState<BankData[]>([]);
    const [account_type, setAccount_type] = useState<AccountData[]>();
    const url_banks = apiURL + 'banks';
    const url_acctype = apiURL + 'acctype';
    const url_generate = apiURL + 'generate';
    const cookieToken = Cookies.get('auth');
    const [startDate, setStartdate] = useState('');
    const [endDate, setenddate] = useState('');
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [responseReport, setResponseReport] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleBank = async () => {
            try {
                const response_1 = await axios.get(url_banks);
                const bank_data = response_1.data;
                setBanks(bank_data.content);
                const response_2 = await axios.get(url_acctype);
                const account_data = response_2.data;
                setAccount_type(account_data.content);
            } catch {
                console.log("Error while fetching bank data");
            }
        }
        handleBank();
    }, []);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const fileList = Array.from(e.target.files);
            setFiles(fileList);
        }
    }

    const handleUpload = async (e: React.FormEvent) => {
        e.preventDefault();
        setButtonDisabled(true);

        const response = document.getElementById('submit');
        if (response) { response.innerText = 'Please wait...'; }

        if (bank) {
            if (cookieToken) {
                const token = cookieToken;
                const formData = new FormData();

                if (files) {

                    files.forEach((file: File, index: number) => {
                        formData.append(`file[]`, file);
                    });

                    formData.set('report_name', reportname);
                    formData.set('bank_id', bank);
                    formData.set('token', token);
                    formData.set('account_type', account);
                    formData.set('start_date', startDate);
                    formData.set('end_date', endDate);
                }

                try {
                    const report_response = await axios.post(url_generate, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    const data = await report_response.data;

                    if (data.response === 'success') {
                        setResponseReport(true);

                        if (response) {
                            response.innerText = "File is uploaded Successfully";
                        }

                        const report_id = data.content.report_id;
                        const report_status = data.content.report_status;
                        const start_date = data.content.start_date;
                        const end_date = data.content.end_date;
                        const report_filename = data.content.report_filename;
                        onreportResponse({ report_id, report_status, start_date, end_date, report_filename });
                        navigate('/viewstatement');
                    }
                    else {
                        navigate('/error?error='+data.content+'debug='+data.errmsg);
                    }
                }
                catch (error) {
                    // Handle errors
                    console.error('Error during passing report:', error);
                }
            }
        }
        else {
            console.log("Bank is null");
        }
    }

    return (
        <div className='container mx-auto mb-40'>
            <div className="py-20  px-2">
                <div className="max-w-md mx-auto p-10 bg-white rounded-lg overflow-hidden border-2 border-solid border-gray-400 border-transparent md:max-w-lg">
                    <form onSubmit={handleUpload} method='post' encType='multipart/form-data'>
                        <div className="md:flex border-2">
                            <div className="w-full">
                                <div className="p-4 border-b-2 text-left">
                                    <span className="text-lg font-bold text-gray-600">Add statement</span>
                                </div>
                                <div className="p-3">
                                    <div className="mb-4 text-left">
                                        <span >Applicant name<sup className='text-[#FF0000]'>*</sup></span>
                                        <input type="text" className="h-12 px-3 w-full border-gray-200 border rounded focus:outline-none focus:border-gray-300" name='report_name' id='reportname' onChange={(e) => setreportName(e.target.value)} required />
                                    </div>
                                    <div className="mb-4 text-left">
                                        <span >Bank name<sup className='text-[#FF0000]'>*</sup></span>
                                        <select onChange={(e) => setBank(e.target.value)} className='h-12 px-3 w-full border-gray-200 border rounded focus:outline-none focus:border-gray-300' name='bank_id' id='bankname' required>
                                            <option value='' disabled selected>Select a bank</option>
                                            {banks.map(x => { return <option key={x.bank_id} value={x.bank_id} >{x.bank_name}</option> })}
                                        </select>
                                    </div>
                                    <div className="mb-4 text-left">
                                        <span>Account type<sup className='text-[#FF0000]'>*</sup></span>
                                        <select className='h-12 px-3 w-full border-gray-200 border rounded focus:outline-none focus:border-gray-300' name='account_type' id='account_type' onChange={(e) => setAccount(e.target.value)} required>
                                            <option value='' disabled selected>Select account</option>
                                            {account_type ? account_type.map(x => { return <option key={x.account_type_id} value={x.account_type_id} >{x.account_type_name}</option> }) : ''}
                                        </select>
                                    </div>

                                    <div className='mb-4 text-left'>
                                        <span>Start date<sup className='text-[#FF0000]'>*</sup></span>
                                        <input type='date' className='h-12 px-3 w-full border-gray-200 border rounded focus:outline-none focus:border-gray-300' onChange={(e) => setStartdate(e.target.value)} name='start_date' id='start_date' required />
                                    </div>

                                    <div className='mb-4 text-left'>
                                        <span>End date<sup className='text-[#FF0000]'>*</sup></span>
                                        <input type='date' min={startDate} className='h-12 px-3 w-full border-gray-200 border rounded focus:outline-none focus:border-gray-300' onChange={(e) => setenddate(e.target.value)} name='end_date' id='end_date' required />
                                    </div>
                                    <div className="mb-4 text-left">
                                        <span>Attachments<sup className='text-[#FF0000]'>*</sup></span>
                                        <div className="relative h-40 rounded-lg border-dashed border-2 border-gray-200 bg-white flex justify-center items-center hover:cursor-pointer">
                                            <div className="absolute">
                                                <div className="flex flex-col items-center ">
                                                    <i className="fa fa-cloud-upload fa-3x text-gray-200"></i>
                                                    <span className="block text-blue-400 font-normal"><input type="file" accept=".pdf" multiple onChange={handleFileChange} name='file' required /></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-[#FF0000] '>{fileError}</div>
                                    </div>

                                    <div className="mt-3 text-center pb-3">
                                        <button type='submit' className="w-full h-12 text-lg  bg-blue-600 rounded text-white hover:bg-blue-700 " name='submit' id='submit' disabled={isButtonDisabled} >Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Generate;
