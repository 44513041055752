
const Home = () => {
    document.title = 'Ram Associates';
    // My name is development server
    return (
        <>
            <div className='container mb-40 mx-auto'>
                <h1 className='text-3xl font-medium my-5'>Welcome to <span className='text-indigo-700'>Ram Associates</span></h1>
            </div>
        </>
    );
};

export default Home;