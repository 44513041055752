import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const apiURL = process.env.REACT_APP_API_URL;

interface LogoutProp {
    onLogout: () => void;
}

const ChangePassword: React.FC<LogoutProp> = ({ onLogout }) => {

    document.title = 'Change password';
    const token = Cookies.get('auth');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const url_password = apiURL + 'change-password';

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword === confirmPassword) {
            if (token) {
                try {
                    const response = await axios.post(url_password, {
                        token: token,
                        new_password: newPassword
                    })

                    const data = response.data;
                    if (data.response === 'success') {
                        document.getElementById('changePassSuccess')?.classList.remove('hidden');
                        setTimeout(() => {
                            console.log("Log out executed");
                            onLogout();
                        }, 10000)

                    }
                    else {
                        document.getElementById('changePassError')?.classList.remove('hidden');

                    }
                }
                catch {
                    console.log("Error while changing password");
                }
            }
        }
        else {
            setError('Password and Confirm Password does not match');
        }
    }

    return (
        <div className="bg-white container w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931] mb-40 mx-auto justify-center">
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4 mx-auto">
                <div className="p-2 md:p-4">
                    <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg mx-auto">
                        <form onSubmit={handleSubmit}>
                            <h2 className="pl-6 text-2xl font-bold sm:text-xl">Change Password</h2>

                            <div className="grid max-w-2xl mx-auto">

                                <div className="items-center sm:mt-14 text-[#202142]">

                                    <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label htmlFor="new_password"
                                                className="block mb-2 text-sm font-medium text-indigo-900 text-left">New password</label>
                                            <input type="password" id="new_password"
                                                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                placeholder="New password" required onChange={(e) => setNewPassword(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-6">
                                        <label htmlFor="confirm_password"
                                            className="block mb-2 text-sm font-medium text-indigo-900 text-left">Confirm password</label>
                                        <input type="password" id="confirm_password"
                                            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                            placeholder="Confirm password" required onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </div>

                                    {error ? <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400 w-full" role="alert">
                                        <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <div>
                                            <span className="font-medium">{error}</span>
                                        </div>
                                    </div> : ''}

                                    <div id='changePassSuccess' className="hidden my-2  bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                                        Password is changed successfully.
                                    </div>

                                    <div id='changePassError' className="hidden  my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                        Password is not changed.
                                    </div>

                                    <div className="flex justify-end mt-2">
                                        <button type="submit"
                                            className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Save</button>
                                    </div>


                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ChangePassword;