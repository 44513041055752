import { jwtDecode } from 'jwt-decode';
import { ChangeEvent, useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

interface UserData {
    name: string;
    email: string;
    phone: string;
}

const apiURL = process.env.REACT_APP_API_URL;

const EditProfile = () => {

    document.title = 'Edit profile';
    const cookieToken = Cookies.get('auth');
    const [UserData, setUserData] = useState<UserData>({ name: '', email: '', phone: '' });
    const [oldName, setOldName] = useState('')
    const [oldEmail, setOldEmail] = useState('')
    const url_editprofile = apiURL + 'edit-profile';
    const url_editprofile_submit = apiURL + 'edit-profile-submit';

    useEffect(() => {
        const fetchUserData = async () => {
            const response = await axios.post(url_editprofile, {
                token: cookieToken,
            })

            const data = await response.data;
            setUserData(data.content[0]);
            setOldEmail(data.name);
            setOldName(data.email);
        }

        fetchUserData();
    }, [])


    const handleData = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await axios.post(url_editprofile_submit, {
                name: UserData.name === oldName ? ' ' : UserData.name,
                email: UserData.email === oldEmail ? ' ' : UserData.email,
                token: cookieToken,
            })
            const data = response.data;
            if (data.response === 'success') {
                document.getElementById('editSuccess')?.classList.remove('hidden');
                Cookies.set('name', UserData.name);
            }
            else {
                document.getElementById('editError')?.classList.remove('hidden');

            }
        }
        catch {
            console.log("Error while editing data");
        }
    }

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    return (
        <div className="bg-white container w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931] mb-40 mx-auto justify-center">
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4 mx-auto">
                <div className="p-2 md:p-4">
                    <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg mx-auto">
                        <h2 className="pl-6 text-2xl font-bold sm:text-xl">Edit Profile</h2>

                        <div className="grid max-w-2xl mx-auto">
                            <form onSubmit={handleData}>
                                <div className="items-center sm:mt-14 text-[#202142]">

                                    <div className="mb-2 sm:mb-6">
                                        <label htmlFor="phone"
                                            className="block mb-2 text-sm font-medium text-indigo-900 text-left">Phone</label>
                                        <input type="text" id="phone"
                                            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                            placeholder="+1234567890" value={UserData?.phone} required disabled />
                                    </div>

                                    <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label htmlFor="name"
                                                className="block mb-2 text-sm font-medium text-indigo-900 text-left">Your name</label>
                                            <input type="text" id="name" name='name'
                                                className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                                placeholder='Your name' value={UserData?.name} required onChange={handleInput} />
                                        </div>
                                    </div>

                                    <div className="mb-2 sm:mb-6">
                                        <label htmlFor="email"
                                            className="block mb-2 text-sm font-medium text-indigo-900 text-left">Your
                                            email</label>
                                        <input type="email" id="email" name='email'
                                            className="bg-indigo-50 border border-indigo-300 text-indigo-900 text-sm rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5 "
                                            placeholder="your.email@mail.com" value={UserData?.email} required onChange={handleInput} />
                                    </div>

                                    <div id='editSuccess' className="hidden my-2  bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                                        Profile is edited successfully.
                                    </div>

                                    <div id='editError' className="hidden  my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                                        Profile is not changed.
                                    </div>

                                    <div className="flex justify-end mt-2">
                                        <button type="submit"
                                            className="text-white bg-indigo-700  hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main >
        </div >
    )
}

export default EditProfile;