const ErrorPage = () => {
    document.title = 'Error';

    return (
        <div className='container mb-40 mx-auto'>
            <div className="shadow-lg shadow-indigo-500/40 m-4">
                <h5 className="my-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">Error in report generation.</h5>
                <div className='text-left p-2 '>Please try again later.</div>
                <a href="/dashboard" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center m-3 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Back to dashboard
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </a>
            </div>
        </div>)
}

export default ErrorPage;