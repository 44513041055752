import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import banner from '../assets/images/login_image.jpg';
import axios from 'axios';
import Cookies from 'js-cookie';

const apiURL = process.env.REACT_APP_API_URL;

interface AuthenticationProp {
    onAuthentication: () => void;
}

const Login: React.FC<AuthenticationProp> = ({ onAuthentication }) => {
    const [phone, setphone] = useState('');
    const [password, setpassword] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    document.title = 'Login';

    const validateForm = () => {
        const PhoneRegex = /^\d{10}$/;
        if (!PhoneRegex.test(phone)) {
            setPhoneError('Please enter a 10 digit phone number.');
            return false;
        }
        else {
            setPhoneError('');
        }

        return true;
    }

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        const url = apiURL + 'login';
        console.log(url);
        if (validateForm()) {
            try {
                console.log(phone, password)
                const response = await axios.post(url, {
                    phone: phone,
                    password: password,
                });
                // Handle the response
                const data = response.data;
                setLoginError(data.content);
                if (data.response === 'success') {
                    Cookies.set('auth', data.content);
                    navigate('/dashboard');
                    onAuthentication();
                }
            } catch (error) {
                // Handle errors
                console.error('Error during login:', error);
            }
        }
    };

    return <>
        <div className='container mb-40 mx-auto'>
            <div className='flex justify-center mt-24 overflow-hidden '>
                <div className='flex border-solid border-indigo-500 border-2 rounded-lg max-w-[650px] max-h-[#350px]'>
                    <div className='p-4 hidden lg:flex w-1/2'><img src={banner} className='max-w-full object-contain'></img></div>
                    <div className='flex flex-col items-center justify-center '>
                        <form onSubmit={handleLogin}>
                            <div className="md:flex">
                                <div className="w-full">
                                    <div className='text-4xl my-5 text-[#7700ff] p-4 text-left'>
                                        <span>Sign  in</span>
                                    </div>

                                    <div className="p-3">
                                        {loginError ?
                                            <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:text-red-400 w-full" role="alert">
                                                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                                </svg>
                                                <span className="sr-only">Info</span>
                                                <div>
                                                    <span className="font-medium">{loginError}</span>
                                                </div>
                                            </div> : ''}
                                        <div className="mb-4 text-left">
                                            <span className='p-1 text-black text-left font-medium'>Phone</span>
                                            <input type='text'
                                                placeholder='Enter 10 Digit Phone'
                                                name='phone'
                                                maxLength={10}
                                                id='phone'
                                                className='w-full border rounded border-gray-300 bg-inherit p-3 shadow shadow-gray-100 mt-2 appearance-none text-neutral-800'
                                                onChange={(e) => setphone(e.target.value)}
                                                required />
                                            <div><span className="text-[#FF0000] p-1 text-wrap w-full">{phoneError}</span></div>
                                        </div>
                                        <div className="mb-4 text-left">
                                            <span className='p-1 text-black text-left font-medium'>Passoword</span>
                                            <input type='password'
                                                placeholder='Password'
                                                name='password'
                                                id='password'
                                                className='w-full border rounded border-gray-300 bg-inherit p-3 shadow shadow-gray-100 mt-2 appearance-none text-neutral-800'
                                                onChange={(e) => setpassword(e.target.value)}
                                                required />
                                        </div>

                                        <button
                                            type='submit'
                                            className='bg-indigo-500 text-white rounded w-full p-3 my-5 hover:bg-indigo-700'
                                        >
                                            LOGIN
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Login;