import React from 'react';

const Footer = () => {
    return <>
        <div className=' bg-[#E9F8F9] p-4 font-medium  mx-0 fixed bottom-0 left-0 right-0'>
            <div className='text-center'>Copyright <span>&#169;</span> 2024 Ram Associates
            </div>
        </div>
    </>
}

export default Footer;      