interface reportData {
    report_id: string;
    report_status: string;
    start_date: string;
    end_date: string;
    report_filename: string;
}

const apiURL = process.env.REACT_APP_API_URL;
const Report: React.FC<{ report_data?: reportData }> = ({ report_data }) => {
    document.title = 'statement';
    const handleDate = (date: string) => {
        const dateObject = new Date(date);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });

        // Combine the formatted date and time
        const result = `${formattedDate}`;
        return result;
    }

    return (
        <div className='container mb-40 mx-auto'>
            <div className="shadow-lg shadow-indigo-500/40 m-4">
                <h5 className="my-2 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">Record is added successfully.</h5>
                <div className='text-left p-2 '>Your statement {report_data?.report_id} is {report_data?.report_status == '0' ? 'generating' : 'generated'}. Please see details as follows.</div>
                <div className='text-left p-2'>Statement from {report_data?.start_date ? handleDate(report_data.start_date).toString() : 'No date available'} to {report_data?.end_date ? handleDate(report_data.end_date).toString() : 'No date available'} is {report_data?.report_status == '0' ? 'generating' : 'generated'}.</div>
                <div className='text-left p-2'>{report_data?.report_status == '0' ? '' : <p><a href={`${apiURL}reports/${report_data?.report_filename}`} target="_blank" className="underline">Click here</a> to download report.</p>}</div>
                <a href="/dashboard" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center m-3 text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Back to dashboard
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </a>
            </div>
        </div>)
}

export default Report;