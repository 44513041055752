import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

interface MyToken {
    user_id: string;
    name: string;
    email: string;
    phone: string;
}

interface UserData {
    name: string;
    report_id: string;
    report_date: string;
    report_status: string;
    bank_id: string;
    bankname: string;
    start_date: string;
    end_date: string;
    report_filename: string;
}

const apiURL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const [user, setUser] = useState({ name: '', user_id: '' });
    const [recordError, setrecordError] = useState('Loading...');
    const [userData, setUserdata] = useState<UserData[]>([]);
    const url_dashboard = apiURL + 'dashboard';
    const url_delete = apiURL + 'delete-report';
    const cookieToken = Cookies.get('auth');
    const userName = Cookies.get('name');
    const navigate = useNavigate();

    document.title = 'Dashboard';

    const handleToken = async (token: string) => {
        try {
            const response = await axios.post(url_dashboard, {
                token,
            });
            const data = response.data;
            setUserdata(data.content);
            if (data.content.length === 0) {
                setrecordError('No record found');
            }
        }
        catch (error) {
            // Handle errors
            console.error('Error during passing token:', error);
        }
    }

    const handleDelete = async (index: number) => {
        const result = window.confirm('Are you sure ?');
        if (result) {
            try {
                const response = await axios.post(url_delete, {
                    token: cookieToken,
                    report_id: userData[index].report_id,
                })
                const data = response.data;

                if (data.response === 'success') {
                    setUserdata(data.content);
                }
            }
            catch {
                console.log("Error while deleting data");
            }
        } else {
            // User clicked "No" or closed the dialog
            console.log('User clicked No or closed the dialog');
        }
    };

    useEffect(() => {
        const handleDecode = () => {
            try {
                if (cookieToken) {
                    // Parse the JSON string to get the actual object
                    const token = cookieToken;
                    const decodedToken = jwtDecode<MyToken>(token);
                    setUser(decodedToken);
                    Cookies.set('name', decodedToken.name);
                    handleToken(token);
                } else {
                    console.log('No data found in sessionStorage');
                }
                // Utilize the decoded data in your component's logic
            } catch (error) {
                console.error('Error decoding token:', error);
                // Handle decoding errors appropriately
            }
        };

        handleDecode();

    }, []);

    useEffect(() => {
        if (cookieToken !== null || cookieToken !== '') {
            navigate('/dashboard');
        }
        else {
            console.error("Token is null");
            navigate('/login');
        }

    }, [cookieToken]);

    const handleDate = (date: string) => {
        const dateObject = new Date(date);
        const formattedDate = dateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
        // Format the time

        const result = `${formattedDate}`;
        return result;
    }

    const downloadFile = async (fileName: string, name: string) => {

        const sanitizeFilename = (filename: string) => {
            return filename.replace(/[^a-z0-9]/gi, '-').toLowerCase(); // Replace non-alphanumeric characters with underscore
        };

        try {
            const url = apiURL + 'reports/' + sanitizeFilename(fileName);
            const response = await axios.get(url, {
                responseType: 'blob',
            });

            const data = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = data;
            link.setAttribute('download', `${name}.xlsx`);

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
        catch (error) {
            console.log("Error while file downloading", error);
        }

    }

    return (
        <div className='container mb-40 mx-auto'>
            <div>
                <div>
                    <h1 className='text-left m-3 text-blue-500 font-medium text-2xl'> Welcome {userName}</h1>
                </div>
                {userData.length > 0 ?
                    <table className="w-full m-3">
                        <thead>
                            <tr>
                                <th
                                    className="font-medium px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs text-gray-700 uppercase tracking-wider"
                                >
                                    Statement Name
                                </th>
                                <th
                                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                                >
                                    Date
                                </th>
                                <th
                                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider"
                                >
                                    Bank name
                                </th>
                                <th
                                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"
                                >
                                    Status
                                </th>
                                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-medium text-gray-700 uppercase tracking-wider"></th>
                            </tr>
                        </thead>
                        {userData.map((x, index) => {
                            return (<tr key={index}>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div >
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {x.name}
                                        </p>
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <p className="text-gray-900 whitespace-no-wrap">from {handleDate(x.start_date)} to {handleDate(x.end_date)}</p>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <div >
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {x.bankname != null ? x.bankname : '-'}
                                        </p>
                                    </div>
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    {x.report_status === '1' ? <span
                                        className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight"
                                    >
                                        <span
                                            aria-hidden
                                            className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                                        ></span>
                                        {/* <span className="relative"><a href={`${apiURL}reports/${x.report_filename}`} target="_blank">Download report</a></span> */}
                                        <button className="relative" onClick={() => { downloadFile(x.report_filename, x.name) }}>Download report</button>
                                    </span> : <span
                                        className="relative inline-block px-3 py-1 font-semibold text-[#ffe100] leading-tight"
                                    >
                                        <span
                                            aria-hidden
                                            className="absolute inset-0 bg-[#ffff0037] opacity-50 rounded-full"
                                        ></span>
                                        <span className="relative">Waiting</span>
                                    </span>}
                                </td>
                                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                    <button className='bg-[#FF0000] p-3 font-medium rounded-xl text-white' onClick={() => handleDelete(index)}>Delete</button>
                                </td>
                            </tr>)
                        })}
                    </table>
                    : <div className='font-medium mt-10'>{recordError}</div>}
            </div>
        </div>
    );
};

export default Dashboard;