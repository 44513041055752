import React, { useState } from 'react';
import Logo from '../assets/images/Ram-Associates-Logo.png';
import avatar from '../assets/images/avatar2.png';

interface HeaderProps {
    isAuthenticated: boolean;
    onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ isAuthenticated, onLogout }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [ismenuOpen, setIsMenuOpen] = useState(false);

    const handleAvatar = () => {
        setIsOpen(!isOpen);
    }

    const handleMenu = () => {
        const menu = document.getElementById('menu');
        setIsMenuOpen(!ismenuOpen);
        if (ismenuOpen) {
            menu?.classList.remove('hidden');
        }
        else {
            menu?.classList.add('hidden');
        }
    }

    return (

        <nav className="bg-[#E9F8F9] py-2 md:py-4">
            <div className="container px-4 mx-auto flex justify-between md:items-center">
                <div className="flex justify-between items-center">
                    <img src={Logo} className='w-32'></img>
                </div>

                <div className="md:flex md:flex-row md:ml-auto md:mt-0" id="navbar-collapse">
                    <div className='absolute top-2 right-2 flex flex-col justify-center items-center z-50 md:hidden' onClick={handleMenu}>
                        <span className='w-8 h-1 m-1 bg-[#cdcdcd] rounded md:hidden'></span>
                        <span className='w-8 h-1 m-1 bg-[#cdcdcd] rounded md:hidden'></span>
                        <span className='w-8 h-1 m-1 bg-[#cdcdcd] rounded md:hidden'></span>
                    </div>

                    <ul className='hidden bg-slate-300 w-screen h-screen md:w-full md-h-full z-50 p-5 absolute right-0 mt-10 md:flex flex-col md:py-2 md:relative md:flex-row md:bg-[#E9F8F9] md:h-full md:mt-0' id='menu'>
                        <a href='/'><li className="p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font-small transition-colors duration-300">Home</li></a>
                        <a href="/about"><li className="p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font -small transition-colors duration-300">About</li></a>
                        {isAuthenticated ? (<>
                            <a href='/dashboard'><li className="p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font-small transition-colors duration-300">View Statement</li></a>
                            <a href='/generate'><li className="p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font-small transition-colors duration-300">Add Statement</li></a>
                            <a href='/editprofile'><li className="md:hidden p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font-small transition-colors duration-300">Edit profile</li></a>
                            <a href='/changepassword'><li className="md:hidden p-2 ml-2 lg:px-4 md:mx-2 text-black text-lg rounded hover:bg-black hover:text-white font-small transition-colors duration-300">Change password</li></a>
                            <a href='/login'><li className="md:hidden p-2 ml-2 lg:px-4 md:mx-2 text-white bg-indigo-500 text-center border border-transparent rounded hover:bg-indigo-700 hover:text-white hover:font-medium transition-colors duration-300"><button onClick={onLogout}>Log out</button></li></a>

                            <div className='relative hidden md:block'>
                                <button id="dropdownUserAvatarButton" data-dropdown-toggle="dropdownAvatar" className="flex text-sm rounded-full hover:focus:ring-4 " type="button" onClick={handleAvatar}>
                                    <img className="w-10 h-10 rounded-full object-cover" src={avatar} alt="user photo" />
                                </button>

                                {/* Dropdown menu */}
                                {isOpen && (
                                    <div id="dropdownAvatar" className="z-10 origin-top-left right-0 absolute mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownUserAvatarButton">
                                            <a href="/editprofile" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><li>Edit profile</li></a>
                                            <a href="/changepassword" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"><li>Change password</li></a>
                                        </ul>
                                        <div className="py-2">
                                            <a href='/login'><li className="p-2 ml-2 lg:px-4 md:mx-2 text-white bg-indigo-500 text-center border border-transparent rounded hover:bg-indigo-700 hover:text-white hover:font-medium transition-colors duration-300"><button onClick={onLogout}>Log out</button></li></a>
                                        </div>
                                    </div>)}
                            </div>

                        </>) : (<a href='/login'><li className="p-2 ml-2 lg:px-4 md:mx-2 text-white bg-indigo-500 text-center border border-transparent rounded hover:bg-indigo-700 hover:text-white hover:font-medium transition-colors duration-300">Login</li></a>)}
                    </ul>
                </div>
            </div>
        </nav >
    );
}

export default Header;