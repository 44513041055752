import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Generate from './pages/Generate';
import Footer from './components/Footer';
import Report from './pages/Report';
import EditProfile from './pages/Editprofile';
import ChangePassword from './pages/Password';
import ErrorPage from './pages/Error';
import Cookies from 'js-cookie';

interface ProtectedRouteProps {
  element: React.ReactElement;
  isAuthenticated: boolean;
}

interface ReportData {
  report_id: string;
  report_status: string;
  start_date: string;
  end_date: string;
  report_filename: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, isAuthenticated }) => {
  return isAuthenticated ? (
    element
  ) : (
    <Navigate to="/login" />
  );
};

function App() {
  const tokenStored = Cookies.get('auth');
  const [isAuthenticated, setisAuthenticated] = useState<boolean>(tokenStored == '' || tokenStored == null ? false : true);
  const [reportData, setReportData] = useState<ReportData>();

  const handleAuthentication = () => {
    setisAuthenticated(true);
  }

  const handlelogout = () => {
    setisAuthenticated(false);
    sessionStorage.removeItem('auth');
    Cookies.remove('auth');
  }

  const handleReportData = (obj: { report_id: string, report_status: string, start_date: string, end_date: string, report_filename: string }) => {
    setReportData(obj);
  }

  return (
    <div className="App">
      <Header isAuthenticated={isAuthenticated} onLogout={handlelogout} />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login onAuthentication={handleAuthentication} />} />
          <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard />} isAuthenticated={isAuthenticated} />} />
          <Route path='/generate' element={<ProtectedRoute element={<Generate onreportResponse={handleReportData} />} isAuthenticated={isAuthenticated} />} />
          <Route path='/viewstatement' element={<ProtectedRoute element={<Report report_data={reportData} />} isAuthenticated={isAuthenticated} />} />
          <Route path='/editprofile' element={<ProtectedRoute element={<EditProfile />} isAuthenticated={isAuthenticated} />} />
          <Route path='/changepassword' element={<ProtectedRoute element={<ChangePassword onLogout={handlelogout} />} isAuthenticated={isAuthenticated} />} />
          <Route path='/error' element={<ProtectedRoute element={<ErrorPage />} isAuthenticated={isAuthenticated} />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
